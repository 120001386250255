<template>
  <div class="container">
    <div class="input-container">
      <el-input
          v-model="inputValue"
          placeholder="请输入文件地址"
          style="width: 70vw;margin-top: 10vh"
          size="medium"
      ></el-input>
    </div>
    <div class="button-container">
      <el-button type="primary" @click="optionDialogVisible = true" icon="el-icon-edit-outline">选项</el-button>
      <el-button :loading="loading" type="success" @click="handleButtonClick" icon="el-icon-video-play">解析</el-button>
      <el-button :loading="loading" type="success" @click="dialogTableVisible = true" icon="el-icon-discover" v-if="parseResponse.downloadUrl.length > 0">上次解析结果</el-button>
    </div>
    <div class="divider"></div>
<!--    <div class="action-container">-->
<!--      <el-button v-if="parseResponse.durl.length>0" :loading="loading" round @click="handleAction1Click">Aria下载</el-button>-->
<!--      <el-button v-if="parseResponse.durl.length>0" :loading="loading" round @click="handleAction2Click">迅雷下载</el-button>-->
<!--    </div>-->

    <el-dialog
        title="选项"
        :visible.sync="optionDialogVisible"
        width="30%">
      <el-checkbox v-model="autoFileSuffix">自动后缀</el-checkbox><br >
      <el-input v-model="fileSuffix" placeholder="请输入后缀,包括. 例如.rar"></el-input>
    </el-dialog>





    <el-dialog :close-on-click-modal="false" title="下载" :visible.sync="dialogTableVisible">
      <el-table :data="parseResponse.downloadUrl">
        <el-table-column property="dom" label="节点" width="100"></el-table-column>
        <el-table-column property="wp" label="所属网盘" width="180"></el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
        >
          <template slot-scope="scope">
            <el-button
                size="mini"
                :loading="loading"
                @click="Download(scope.$index, scope.row, 1)">RPC</el-button>
            <el-button
                size="mini"
                :loading="loading"
                @click="Download(scope.$index, scope.row, 0)">迅雷</el-button>
            <el-button
                size="mini"
                :loading="loading"
                @click="Download(scope.$index, scope.row, 2)">IDM</el-button>
            <el-button
                size="mini"
                :loading="loading"
                @click="Download(scope.$index, scope.row, 3)">扩展下载</el-button>

          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inputValue: '',
      loading: false,
      optionDialogVisible: false,
      dialogTableVisible: false,
      autoFileSuffix: false,
      fileSuffix: '',
      parseResponse:{
        downloadUrl:[]
      }
    };
  },
  mounted() {

  },
  methods: {
    handleButtonClick() {
      // 处理按钮点击事件
      this.loading = true
      const card = localStorage.getItem('card')
      if(!card || card.length < 16){
        this.$message.error('未登录,请到设置页面登录后重试')
        this.loading = false
        return
      }
      let item = this.inputValue
      if(item){
        item = this.$StringUtil.extractUrl(item)
      }
      if(!this.$StringUtil.isUrl(item)){
        this.$message.error('请输入正确的文件地址')
        this.loading = false
        return
      }
      this.$webview.parse(card, this.inputValue).then((response) => {
        if(response.code == 200){
          this.dialogTableVisible = true
          response.data.downloadUrl = []
          this.parseResponse = response.data
          response.data.durl.forEach((item) => {
            this.parseResponse.downloadUrl.push({
              url: item,
              dom: `节点${this.$StringUtil._md5(item, 4)}`,
              wp: response.data.wp,
              referer: response.data.referer,
              name: response.data.fname
            })
          })
          this.loading = false
        }else{
          if(response.message){
            this.$message.error(response.message)
          }else{
            this.$message.error(response.error)
          }
          this.loading = false
        }
      })
    },
    handleAction1Click() {
      // 处理操作按钮1点击事件
    },
    handleAction2Click() {
      // 处理操作按钮2点击事件
    },
    Download(index, e, type){
      this.loading = true
      e.name = this.$StringUtil.formatFilename(e.name)
      if(this.autoFileSuffix){
        e.name = e.name + this.fileSuffix
      }
      if(!e.url){
        this.$message.warning('未获取下载地址')
        this.loading = false
        return
      }else{
        const card = localStorage.getItem('card')
        const save_path = localStorage.getItem('downloadDir')
        const rpc_url = localStorage.getItem('ariaRPC')
        const rpc_token = localStorage.getItem('ariaToken')
        if(!rpc_url){
          this.$message.error('未设置RPC地址')
          this.loading = false
          return
        }
        if(type == 1){
          this.$webview.downloadByAria(this.$StringUtil._md5(this.inputValue + card, 16),
              e.url,
              e.name,
              save_path,
              rpc_url,
              rpc_token,
              e.referer
          ).then(r => {
            if(r.id){
              this.$message.error(r)
            }else{
              this.$message.success('推送RPC下载成功')
            }
            this.loading = false
          })
        }else if(type == 0){
          this.$webview.downloadByThunder(e.name,
              e.url,
              save_path,
              e.referer
          )
          this.loading = false
        }else if(type == 2){
            this.$webview.downloadByIDM(e.url,
                e.name,
                save_path,
                e.referer,
                null
            )
          this.loading = false
        }else if(type == 3){
          window.open(e.url)
        }
      }
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-container {
  margin-top: 20px;
}

.button-container {
  margin-top: 20px;
}

.divider {
  margin: 20px 0;
  width: 100%;
  border-bottom: 1px solid #ccc;
}

.action-container {
  margin-bottom: 20px;
}
</style>
